@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  width: 100%;
  overflow-x: hidden;
}

.container {
  max-width: 100%;
  overflow-x: hidden;
}
