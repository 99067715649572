.profile-intro {
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.profile-intro__copy {
  width: 100%;
  padding: 1rem;
}

.profile-intro__heading {
  font-size: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-wrap: wrap;
}

.profile-ul {
  display: flex;
  flex-wrap: wrap;
  gap: 0.85rem;
  justify-content: center;
}

.profile-li {
  list-style: square;
  width: 60%;
}

.profile-li::marker {
}
